@import 'assets/scss/_functions.scss';
@import 'assets/scss/_variables.scss';
@import 'assets/scss/_mixins.scss';

.input {
  border: 2px solid $gray-100;
  outline: none;
}

.input:focus {
  border: 2px solid $gray-300;
  outline: none;
}

.button:hover,
.button:focus {
  outline: none;
  background-color: $red-100;
  border-color: $red-300 !important;
  box-shadow: none;
}
.loginFormWrapper {
  position: relative;
  padding: $spacer-lg;
  background-color: $white;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  margin-bottom: $spacer-md;
  z-index: 2;
}
.form {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border-color: $gray-200;
    -webkit-text-fill-color: $gray-900;
    -webkit-box-shadow: 0 0 0px 1000px rgba($gray-100, 0.5) inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}
